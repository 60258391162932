$font-title: 'Comfortaa', cursive;

/* Colors */
$red-coral: #EB4250;
$dark-coral: #bc3540;
$d-grey: #101820;
$m-grey: #808080;
$l-grey: #F5F7F9;
$green: #17B660;
$dark-green: #178f3e;
$yellow: #FFD707;
$blue: #407BFF;

html {height: 100%; }
body {
	font-family: 'Roboto', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif;
	font-size: 16px;
	color: $d-grey;
	min-height: 100%;
	&.menu-open {
		@media (max-width: 600px) {
			height: 100vh;
			overflow: hidden;
		}
	}
}

/* bootstrap override */
.container {max-width: 1560px; }
.container.mw-1050 {max-width: 1050px; }
.container.mw-1300 {max-width: 1300px; }
.btn:focus {box-shadow: none; }
img {max-width: 100%; }
.form-control::placeholder {
	font-size: 14px;
	color: #A6A6A6;
	line-height: 32px;
}
select.form-control {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='%23101820' d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'%3E%3C/path%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: right 20px top 50%;
	background-size: 10px;
	padding-right: 40px;
}
.tooltip-inner {
	background: #FFFFFF;
	border-radius: 10px;
	padding: 10px 20px;
	font-size: 14px;
	color: #000;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}
.bs-tooltip-bottom .arrow::before {
	border-width: 0 1rem 1rem;
	border-bottom-color: #FFF!important;
}


/* common */

.red-color {
	color: $red-coral!important;
}
a.red-color:hover {
	color: #000000;
}
.small-text {
	font-size: 14px!important;
}
.uppercase {
	text-transform: uppercase!important;
}
#to-top {
	display: none;
	position: fixed;
	width: 50px;
	height: 50px;
	right: 10px;
	bottom: 50px;
	background-color:$m-grey;
	color: #FFFFFF;
	border: none;
	border-radius: 4px;
	padding: 0;
	i {
		font-size: 32px;
	}
}
body.rtl #to-top {
	right: auto;
	left: 10px;
}

/* buttons */

.btn-pill {
	display: inline-block;
	position: relative;
	border-radius: 20px;
	font-size: 14px;
	line-height: 45px;
	font-weight: 500;
	text-decoration: none;
	height: 36px;
	background-color: #FFF;
	color:  $d-grey;
	border: 1px solid $d-grey;
	padding-left: 25px;
	padding-right: 25px;
	white-space: nowrap;
	transition: background-color .3s, color .3s, border .3s;
	cursor: pointer!important;
	&:active, &:focus {
		outline: none;
	}
	&:hover {
		text-decoration: none;
	}
	&.coral-in {
		background-color:  $red-coral;
		color:  #FFF;
		border: 1px solid $red-coral;
		&:hover {
			background-color: $dark-coral;
			border: 1px solid $dark-coral;
		}
	}
	&.coral-out {
		background-color: #fff;
		color:  $red-coral;
		border: 1px solid $red-coral;
		&:hover {
			background-color: $red-coral;
			border: 1px solid $red-coral;
			color: #FFFFFF;
			line-height: normal;
		}
	}
	&.black {
		background-color: #fff;
		color:  $d-grey;
		border: 1px solid $d-grey;
		font-size: 14px;
		&:hover {
			border: 1px solid $red-coral;
			color: $red-coral;
		}
	}
	&.grey {
		border: 1px solid #ececec;
		&:hover {
			border: 1px solid $red-coral;
			color: $red-coral;
		}
	}
	&.clear {
		background-color: #fff;
		color: #A1A3A6;
		border: 1px solid #D9D9D9;
		min-width: 87px;
	}
	&.big {
		font-size: 16px;
		/*line-height: 50px;*/
		height: 50px;
		border-radius: 25px;
		text-transform: uppercase;
	}
	&.medium {
		font-size: 16px;
		line-height: 40px;
		height: 40px;
		border-radius: 20px;
	}
	&.green {
		background: #27AE60;
		border: 1px solid #27AE60;
		color: #FFFFFF;
		&:hover {
			background: $dark-green;
			border: 1px solid $dark-green;
			line-height: normal;
		}
	}
	&.blue {
		background: #FFFFFF;
		border: 1px solid $blue;
		line-height: 34px;
		color: $blue;
	}
	&.disabled, &:disabled {
		background: #E0E0E0;
		color: #BFBFBF;
		border: 1px solid #E0E0E0;
		cursor: not-allowed;
		&:hover {
			background: #E0E0E0;
			color: #BFBFBF;
			border: 1px solid #E0E0E0;
		}
	}
	&.yellow-out {
		border: 1px solid #FFCB08;
		color: $d-grey;
		&:hover {
			border: 1px solid $red-coral;
			color: $red-coral;
			i {
				color: $red-coral;
				transition: color .3s;
			}
		}
		i {
			color: #FFCB08;
			transition: color .3s;
		}
	}
	&.yellow-in {
		border: 1px solid #FFCB08;
		color: #000000;
		background-color: #FFCB08;
		&:hover {
			border: 1px solid $red-coral;
			color: #FFFFFF;
			background-color: $red-coral;
			i {
				color: #FFFFFF;
				transition: color .3s;
			}
		}
		i {
			color: #000000;
			transition: color .3s;
		}
	}
	i {
		margin-right: 7px;
	}
}
.icon-login {
	background-image: url("../images/core/icon-login-red.svg");
	background-position: 50% 50%;
	background-repeat: no-repeat;
	&:hover {
		background-image: url("../images/core/icon-login-white.svg");
	}
}
.icon-new-doc {
	background-image: url('../images/core/icon-new-red.svg');
	background-repeat: no-repeat;
	background-position: 20px  50%;
	padding-left: 50px;
	&:hover {
		background-image: url('../images/core/icon-new-white.svg');
	}
}
.icon-new-doc-black {
	background-image: url('../images/core/icon-new-black.svg');
	background-repeat: no-repeat;
	background-position: 20px  50%;
	padding-left: 50px!important;
}
.icon-people {
	background-image: url('../images/core/icon-people-black.svg');
	background-repeat: no-repeat;
	background-position: 20px  50%;
	padding-left: 50px!important;
}
.icon-gear {
	background-image: url('../images/core/icon-gear-red.svg');
	background-repeat: no-repeat;
	background-position: 50%  50%;
	padding-left: 50px;
	&:hover {
		background-image: url('../images/core/icon-gear-white.svg');
	}
}
.icon-logout {
	background-image: url('../images/core/icon-logout-red.svg');
	background-repeat: no-repeat;
	background-position: 60%  50%;
	padding-left: 50px;
	&:hover {
		background-image: url('../images/core/icon-logout-white.svg');
	}
}
.icon-cross {
	background-image: url('../images/core/icon-cross-black.svg');
	background-repeat: no-repeat;
	background-position: 50%  50%;
	padding-left: 50px;
	&:hover {
		background-image: url('../images/core/icon-cross-white.svg');
	}
}



/* Header & nav menu */

header .navigation {
	@media (max-width: 1200px){
		/* position: fixed; */
		/* top: 0; */
		width: 100%;
		max-width: 100vw;
		z-index: 9;
		background-color: #fff;
	}
}

.nav-zero {
	position: relative;
	@media (max-width: 1200px){
		height: 100px;
		max-width: 100vw;
		z-index: 110;
	}
	.menu-open & {
		position: fixed;
	}
}
.logo-header {
	display: block;
	position: absolute;
	z-index: 109;
	left: 15px;
	top: 15px;
	width: 360px;
	height: 70px;
	display: block;
	img {
		width: auto;
		height: auto;
		max-width: 100%;
		max-height: 100%;
	}
	@media (max-width: 1200px){
		width: 320px;
		display: flex;
		align-items: center;
	}
	@media (max-width: 600px){
		width: 260px;
	}
	@media (max-width: 350px){
		width: 220px;
	}
}
.nav-bar {
	@media (max-width: 1200px){
		display: flex;
		flex-direction: column-reverse;
		justify-content: flex-end;
		position: fixed;
		height: 100%;
		top: 0px;
		max-width: 0px;
		background-color: #eee;
		overflow-y: auto;
		overflow-x: hidden;
		padding-top: 100px;
		transition: max-width 0.3s;
		z-index: 99;
	}
	@media (max-width: 1200px) and (min-width: 600px){
		.container {
			width: 400px;
			max-width: 400px;
			margin-left: 0;
			margin-right: 0;
		}
		.menu-open & {
			max-width: 400px;
		}
	}
	@media (max-width: 600px){
		.container {
			width: 100vw;
			max-width: 100vw;
			margin-left: 0;
			margin-right: 0;
		}
		.menu-open & {
			max-width: 100vw;
			width: 100vw;
		}
	}
	@media(orientation: landscape) and (max-width: 820px){
		.nav-bar {
			background-color: #000;
		}
		.menu-open & {
			max-width: 100vw;
			width: 100vw;
		}
	}
	@media(orientation: landscape) and (max-width: 820px){
		flex-direction: row-reverse;
		flex-wrap: nowrap;
		.nav-first {
			max-width: 300px;
		}
	}
}
.nav-first > .row {
	@media (min-width: 1200px){
		margin-left: 360px;
		min-height: 100px;
		display: flex;
	}
	@media (max-width: 1200px){
		display: block;
	}
}
.nav-first-inner {
	@media (min-width: 1200px){
		display: flex;
		width: 100%;
	}
	@media (max-width: 1200px){
		display: flex;
		flex-direction: column-reverse;
		margin: 10px;
	}
}
.nav-first-right {
	@media (max-width: 1200px){
		flex-direction: column-reverse;
	}
	.btn-round, .btn-pill, .search-form {
		margin-right: 20px;
		background-color: #FFF;
		@media (max-width: 1200px){
			margin: 5px 5px;
		}
	}
	.btn-round:last-child {
		margin-right: 0;
	}
}
.nav-second {
	@media (min-width: 1200px){
		background-color: $l-grey;
		display: block;
		.container {
			padding-top: 9px;
			padding-bottom: 9px;
		}
	}
	@media (max-width: 1200px){
		width: 100vw;
	}
	& .search-form {
		@media (max-width: 1200px){
			margin: 20px 20px 10px 20px;
		}
		@media (max-width: 1300px) and (min-width: 1200px){
			width: 250px;
		}
	}
}
.main-menu {
	padding: 0;
	margin: 0;
	li {
		list-style: none;
		@media (min-width: 1200px){
			display: inline-block;
			vertical-align: middle;
			margin-left: 27px;
			margin-right: 27px;
		}
		@media (max-width: 1200px){
			display: block;
			text-align: left;
			margin: 10px 0 10px 20px;
		}
		a {
			display: block;
			text-transform: uppercase;
			font-weight: 500;
			color: $d-grey;
			font-size: 18px;
			line-height: 42px;
			min-height: 42px;
			transition: color .3s;
			@media (max-width: 1200px){
				line-height: inherit;
				min-height: auto;
			}
		}
		a:hover {
			text-decoration: none;
			color: $red-coral;
		}
	}
}

.language-selector {
	& .dropdown-toggle {
		background-color: #fff;
		border-radius: 20px;
	}
	@media (min-width: 1200px){
		margin-left: 40px;
	}
	@media (max-width: 1300px) and (min-width: 1200px){
		margin-left: 10px;
		.dropdown-toggle {
			/* font-size: 0; */
			i {
				display: none;
			}
			&::after {
				font-size: 16px;
			}
		}
	}
	.fas {
		color: $red-coral;
	}
	.dropdown-toggle::after {
		color: $red-coral;
	}
	.lng-full {
		@media(min-width: 1200px) and (max-width: 1300px){
			display: none;
		}
	}
	.lng-short {
		display: none;
		@media(min-width: 1200px) and (max-width: 1300px){
			display: inline;
		}
	}
	.dropdown-menu {
		@media(min-width: 1200px) and (max-width: 1300px){
			min-width: 0;
		}
	}
}

.search-form {
	border: 1px solid #ECEEF0;
	background-color: #fff;
	border-radius: 20px;
	position: relative;
	width: 320px;
	max-width: 100%;
	@media(max-width: 370px){
		width: 260px;
	}
	input[type="text"] {
		background: transparent;
		border: none;
		height: 40px;
		width: calc(100% - 45px);
		padding: 5px 20px;
		&::placeholder {
			font-size: 14px;
		}
	}
	.search-submit {
		display: block;
		position: absolute;
		right: -1px;
		top: -1px;
		height: 42px;
		width: 42px;
		padding: 0;
		background-color: #fff;
		border: 1px solid #ECEEF0;
		border-radius: 20px;
	}
}

.btn-round {
	display: inline-block;
	vertical-align: middle;
	border-radius: 20px;
	color:  $red-coral;
	font-size: 16px;
	line-height: 40px;
	font-weight: 500;
	border: 1px solid #DCDCDC;
	text-transform: uppercase;
	height: 40px;
	background-color: #FFF;
	transition: background-color .3s, border .3s, color .3s;
	&.coral-out {
		border: 1px solid $red-coral;
	}
	i {
		color: inherit;
	}
	@media (min-width: 1200px){
		/* margin-left: 20px; */
	}
	@media (max-width: 1200px){
		/* margin: 25px 10px 25px 0; */
	}
	&:hover {
		background-color: $red-coral;
		border: 1px solid $red-coral;
		color: #FFFFFF;
	}
}
.btn-register {
	padding-left: 25px;
	padding-right: 25px;
	max-width: 125px;
}
.btn-round {
	width: 40px;
	height: 40px;
	padding: 0;
}



.navigation-toggle {
	display: none;
	position: absolute;
	right: 30px;
	top: 25px;
	background-color: $l-grey;
	border: 1px solid #ECEEF0;
	border-radius: 10px;
	width: 50px;
	height: 50px;
	text-align: center;
	padding: 9px;
	span {
		display: block;
		position: relative;
		width: 30px;
		height: 4px;
		border-radius: 2px;
		background-color: #aaa;
	}
	span::after, span::before {
		content: '';
		display: block;
		position: absolute;
		width: 30px;
		height: 4px;
		border-radius: 2px;
		background-color: #aaa;
		transition: all .3s;
	}
	span::before {
		top: 10px;
	}
	span::after {
		bottom: 10px;
	}
	&.active span {
		background-color: transparent;
	}
	&.active span::before {
		-webkit-transform: rotate(45deg) translate(-7px, -7px);
		transform: rotate(45deg) translate(-7px, -7px);
	}
	&.active span::after {
		-webkit-transform: rotate(-45deg) translate(-7px, 7px);
		transform: rotate(-45deg) translate(-7px, 7px);
	}
	@media(max-width: 1200px){
		display: block;
	}
}

/* footer */

.footer {
	background-color: $l-grey;
	.footer-first {
		@media (max-width: 900px) {
			flex-direction: column;
		}
		& > div {
			@media (max-width: 900px) {
				margin-bottom: 20px;
			}
		}
	}
	.logo-footer {
		display: block;
		img {
			max-width: 200px;
			max-height: 150px;
		}
	}
	.footer-second {
		border-top: 2px solid #EBEDF0;
		font-size: 14px;
		color: $m-grey;
	}
	.footer-menu {
		padding: 0;
		margin: 0;
		column-count: 2;
		column-width: 50%;
		column-gap: 70px;
		@media(max-width: 768px){
			column-gap: 30px;
		}
		li {
			list-style: none;
			margin-bottom: 10px;
			a {
				text-decoration: none;
				transition: color .3s;
				color: $d-grey;
			}
			a:hover {
				color: $red-coral;
			}
		}
	}
	.social-media {
		margin: 0;
		padding: 0;
		li {
			list-style: none;
			display: inline-block;
			a {
				display: block;
				background: #FFFFFF;
				border: 1px solid #EBEDF0;
				box-sizing: border-box;
				border-radius: 6px;
				text-align: center;
				width: 50px;
				height: 50px;
				line-height: 50px;
				color: $d-grey;
				transition: background-color .3s, color .3s;
				&:hover {
					color: #FFFFFF;
					background-color: $red-coral;
				}
				i {
					color: inherit;
					font-size: 24px;
					line-height: 50px;
				}
			}
		}
	}
}

.main {
	@media (min-width: 1200px){
		min-height: calc(100vh - 426px);
		padding-top: 70px;
	}
	@media (max-width: 1200px){
		padding-top: 20px;
	}
	@media (max-width: 1200px)  {
		.menu-open & {
			padding-top: 120px;
		}
	}
}


/* Section page hero */

.page-hero {
	@media (min-width: 1200px){
		padding: 10px 0 80px 0;
	}
	@media (max-width: 1200px){
		padding: 10px 0 40px 0;
	}
	.page-hero-title {
		display: block;
		position: relative;
		font-family: $font-title;
		font-style: normal;
		font-weight: bold;
		font-size: 48px;
		padding-left: 20px;
		line-height: 120%;
		text-transform: uppercase;
		color: $d-grey;
		&::before {
			content: '';
			display: block;
			position: absolute;
			background-color: $red-coral;
			width: 4px;
			height: 100%;
			left: 0;
			top: 0;
			border-radius: 2px;
		}
		@media (max-width: 1200px){
			font-size: 40px;
		}
		@media (max-width: 800px){
			font-size: 32px;
		}
		@media (max-width: 320px){
			font-size: 24px;
		}
	}
	.page-hero-description {
		font-size: 18px;
		font-weight: normal;
		color: $m-grey;
	}
	.btn-pill {
		font-size: 16px;
		line-height: 40px;
		height: 40px;
	}
}


.section-title {
	position: relative;
	font-family: $font-title;
	font-style: normal;
	font-weight: bold;
	font-size: 48px;
	text-align: center;
	color: $d-grey;
	margin-bottom: 40px;
	padding-bottom: 0px;
	margin-left: auto;
	margin-right: auto;
	max-width: 1000px;
	&::before {
		content: '';
		display: block;
		position: absolute;
		background-color: $red-coral;
		width: 100px;
		height: 4px;
		left: calc(50% - 50px);
		bottom: -15px;
		border-radius: 2px;
	}
	@media (max-width: 1200px){
		font-size: 40px;
		max-width: 90%;
	}
	@media (max-width: 768px){
		font-size: 36px;
	}
	@media (max-width: 576px){
		font-size: 24px;
	}
	@media (max-width: 360px){
		font-size: 22px;
	}
}
.section-title.medium {
	font-size: 36px;
}
.section-title.small {
	font-size: 24px;
}
.section-description {
	font-weight: normal;
	font-size: 18px;
	line-height: 180%;
	text-align: center;
	color: $m-grey;
	margin-bottom: 40px;
	@media (max-width: 360px){
		font-size: 16px;
	}
}
.section-title.no-redline {
	margin-bottom: 30px;
	&::before {
		display: none;
	}
}



/* pad */
.pad {
	display: block;
	position: relative;
	background-color: #fff;
	box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	height: 100%;
	margin-bottom: 30px;
	.number {
		display: block;
		position: absolute;
		top: -27px;
		width: 54px;
		height: 54px;
		background-color: #fff;
		left: calc(50% - 27px);
		border-radius: 27px;
		text-align: center;
		font-family: $font-title;
		font-size: 24px;
		line-height: 54px;
		font-weight: bold;
		border: 1px solid $l-grey;
	}
	img {
		display: block;
		margin-left: auto;
		margin-right: auto;
		max-width: 100%;
	}
	.pad-title {
		font-family: $font-title;
		font-size: 24px;
		font-weight: bold;
		margin-bottom: 0;
	}
	p {
		font-size: 14px;
		line-height: 180%;
		color: $m-grey;
		margin: 0;
	}
}
.pad.centred {
	text-align: center;
	.pad-title {
		margin: 0;
		padding: 10px;
	}
	img {
		padding: 40px 0px 10px 0px;
	}
	p {
		padding: 10px 30px;
	}
}
.pad.price {
	display: flex;
	flex-direction: column;

	text-align: left;
	position: relative;
	padding-bottom: 24px;
	padding-top: 30px;
	margin-bottom: 0;
	.pad-title {
		margin: 0;
		padding: 0px 40px 7px 40px;
		display: block;
		position: relative;
		@media(max-width: 768px){
			font-size: 20px;
		}
		@media(max-width: 400px){
			padding: 0px 20px 7px 20px;
		}
		&::before {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			height: 100%;
			width: 2px;
			background-color: $red-coral;
		}
	}
	p {
		padding: 0px 40px 7px 40px;
		@media(max-width: 400px){
			padding: 0px 20px 7px 20px;
		}
	}
	.pad-btm-item {
		//display: block;
		//position: absolute;
		padding-right: 40px;
		padding-left: 40px;
		//bottom: 30px;
		//left: 40px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: auto;
		@media(max-width: 400px){
			flex-direction: column;
			padding-left: 0;
			padding-right: 0;
		}
		.btn-pill {
			height: 36px;
			font-size: 14px;
			line-height: 36px;
			color: $red-coral;
			border: 1px solid #ececec;
			transition: background-color .3s, color .3s;
			&:hover {
				background-color: $red-coral;
				color: #FFFFFF;
				text-decoration: none;
			}
			i {
				margin-right: 5px;
			}
		}
		.price {
			display: inline-block;
			vertical-align: middle;
			margin-left: 20px;
			font-family: $font-title;
			font-size: 20px;
			font-weight: bold;
		}
		@media (max-width: 395px) {
			text-align: center;
		}
	}
	@media (max-width: 395px) {
		padding-bottom: 27px;
	}
}
.pad.full {
	width: 100%;
	padding: 70px 130px;
	@media (max-width: 768px) {
		padding: 35px 75px;
	}
}
.pad.history {
	padding: 18px 20px 18px 40px;
	margin-bottom: 0;
	position: relative;
	&:before {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		top: 20%;
		height: 60%;
		width: 2px;
		background: $blue;
	}
	.pad-title {
		font-family: inherit;
		font-weight: 500;
		font-size: 18px;
		margin-bottom: 0px;
	}
	.date {
		font-size: 14px;
		font-weight: normal;
		span:first-child {
			padding-right: 10px;
			border-right: 1px solid #E7E9EB;
		}
		span:last-child {
			padding-left: 10px;
		}
	}
	.marker {
		display: inline-block;
		font-size: 12px;
		padding: 3px 15px;
		margin-top: 5px;
		background: $l-grey;
		border-radius: 5px;
	}

}


/* sections */


.city-bg {
	display: block;
	position: relative;
	padding-top: 70px;
	margin-top: 180px;
	background: linear-gradient(to bottom, $l-grey,  $l-grey 400px, #FFFFFF 401px, #FFFFFF 100%);
	&::before {
		content: '';
		display: block;
		position: absolute;
		width: 977px;
		height: 137px;
		top: -136px;
		left: calc(50% - 488px);
		background-image: url('../images/core/city-bg.svg');
		background-repeat: no-repeat;
		background-position: 50% 100%;
		background-size: contain;
		@media (max-width: 1200px) {
			width: 100%;
			height: 69px;
			top: -68px;
			left: 50%;
			transform: translate(-50%);
		}
	}
	@media (max-width: 1200px) {
		margin-top: 100px;
	}
}
.section-grey {
	padding: 70px 0 0 0;
	background: linear-gradient(to bottom, $l-grey, $l-grey 400px, #FFFFFF 401px, #FFFFFF 100%);
}
.section-grey-out {
	background: linear-gradient(to bottom, $l-grey, $l-grey 50%, #FFFFFF 50%, #FFFFFF 100%);
	margin-top: 120px;
	.pad.shift-out {
		margin-top: -70px;
	}
}
.section-grey-solid {
	background-color: $l-grey;
	padding: 30px 0 50px 0;
}
.section-grey-thin {
	background-color: $l-grey;
	padding: 15px 0 15px 0;
}
.section-grey-solid-out {
	background-color: $l-grey;
	margin-top: 120px;
	.pad.shift-out {
		margin-top: -70px;
	}
}


.sections-list {
	.coral-out {
		border: 1px solid #ececec;
	}
}
.contributors-container {
	display: block;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	max-width: 1300px;
	@media (max-width: 1500px) and (min-width: 768px) {
		max-width: calc(100% - 200px);
	}
	@media (max-width: 768px) {
		max-width: calc(100% - 50px);
	}
}
.slick-prev, .slick-next {
	display: block;
	/* width: 60px; */
	width: 30px;
	height: 60px;
	position: absolute;
	top: calc(50% - 50px);
	background-color: transparent;
	/* border-radius: 10px; */
	/* border: 1px solid #ECEEF0; */
	border: none;
	padding: 0;
	line-height: 60px;
	font-size: 0;
	z-index: 9;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	/* background-size: 9px 21px; */
	background-size: 20px 32px;
	opacity: 0.3;
	i {
		font-size: 18px;
	}
}
.slick-prev {
	left: -70px;
	background-image: url("data:image/svg+xml,%3Csvg  xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'%3E%3Cpath fill='currentColor' d='M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z'%3E%3C/path%3E%3C/svg%3E");
	@media (max-width: 768px) {
		left: -20px;
	}
}
.slick-next {
	right: -70px;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'%3E%3Cpath fill='currentColor' d='M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z'%3E%3C/path%3E%3C/svg%3E");
	@media (max-width: 768px) {
		right: -20px;
	}
}


.contributors-slide-item {
	padding: 0 15px 10px 15px;
	.pad {
		display: flex;
		align-items: flex-start;
		padding: 30px;
		height: 100%;
		@media (min-width: 1200px) {
			margin-bottom: 0;
		}
		@media (max-width: 768px) {
			flex-direction: column;
			align-items: center;
		}
	}
	.pad-img {
		min-width: 170px;
		width: 170px;
		height: 170px;
		border-radius: 10px;
		overflow: hidden;
		position: relative;
		&::after {
			content: '';
			display: block;
			position: absolute;
			top: 5px;
			left: 5px;
			width: 160px;
			height: 160px;
			border-radius: 8px;
			border: 2px solid #FFFFFF;
		}
	}
	.pad-text {
		padding-left: 20px;
	}
	.cs-title {
		font-family: $font-title;
		font-weight: bold;
		font-size: 20px;
		line-height: 120%;
		margin-bottom: 7px;
		@media (max-width: 768px) {
			text-align: center;
			margin-top: 15px;
		}
	}
	.cs-subtitle {
		font-size: 16px;
		margin-bottom: 10px;
		@media (max-width: 768px) {
			text-align: center;
			margin-top: 10px;
		}
	}
}

.filter-name {
	display: inline-block;
	vertical-align: middle;
	font-family: $font-title;
	font-size: 20px;
	font-weight: bold;
	color: #000000;
	margin-right: 20px;
}
.btn-dropdown {
	border: 1px solid #E0E0E0;
	border-radius: 5px;
	font-size: 16px;
	min-width: 170px;
	text-align: left;
	padding-left: 20px;
	&::after {
		margin-left: 25px;
	}
}
.shop-filters {
	padding: 45px;
	margin-bottom: 0;
	@media(max-width: 576px){
		padding: 25px;
	}
	.shop-filters-header {
		padding-bottom: 10px;
	}
	.shop-filters-toggle {
		@media(max-width: 1200px){
			display: none;
		}
	}
	.filter-section {
		padding: 20px 0px 20px 20px;
		border-top: 2px solid #F0F0F0;
		.filter-section-name {
			font-weight: 500;
			font-size: 20px;
			color: #000000;
		}
		.search-form {
			margin-top: 25px;
		}
		.filter-section-list {
			margin-top: 25px;
			overflow-y: auto;
			max-height: 420px;
		}
	}
}

.checkbox-container {
	position: relative;
	display: block;
	min-height: 26px;
	padding-left: 38px;
	margin-bottom: 20px;
	input[type="checkbox"] {
		position: absolute;
		z-index: -1;
		opacity: 0;
	}
	label {
		margin-bottom: 0;
		line-height: 26px;
	}
	label::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: 26px;
		height: 26px;
		background-color: #FFFFFF;
		border-radius: 6px;
		border: 1px solid #D9D9D9;
	}
	label::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: 26px;
		height: 26px;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 50% 50%;
		border-radius: 6px;
	}
	& input[type="checkbox"]:checked ~ label::after {
		background-color: $red-coral;
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
	}
}
span.checkbox-container {
	display: inline-block;
	vertical-align: baseline;
	margin-bottom: 0;
}
.switch-container {
	display: inline-block;
	background: #FFFFFF;
	border: 1px solid #F0F0F0;
	border-radius: 24px;
	height: 47px;
	padding: 5px;
	font-weight: 500;
	.btn-pill {
		display: inline-block;
		vertical-align: middle;
		width: 120px;
		height: 35px;
		line-height: 35px;
	}
	span {
		display: inline-block;
		vertical-align: middle;
		border-radius: 24px;
		width: 120px;
		height: 35px;
		font-size: 16px;
		line-height: 35px;
		text-align: center;
	}
}

.buttons-list {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
}
.buttons-list-center {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	& .btn-pill {
		margin: 0 10px;
		height: 40px;
		font-size: 16px;
		line-height: 40px;
		margin-bottom: 15px;
		transition: background-color .3s, color .3s;
	}
	& .btn-pill.current {
		background-color: #fff;
		color:  $red-coral;
		border: 1px solid $red-coral;
	}
	& .btn-pill:hover {
		color: #FFFFFF;
		background-color: $red-coral;
		border: 1px solid $red-coral;
	}
	& .btn-pill.blue:hover {
		background: $blue;
		border: 1px solid $blue;
		color: #FFFFFF;
	}
	.buttons-list-left, .buttons-list-right {
		flex-basis: 50%;
	}
	.buttons-list-left {
		text-align: right;
		@media(max-width: 540px){
			text-align: center;
		}
	}
	.buttons-list-right {
		text-align: left;
		@media(max-width: 540px){
			text-align: center;
		}
	}
}
.btn-pill span.num {
	position: absolute;
	display: block;
	background-color: #fff;
	text-align: center;
	color: $red-coral;
	text-align: center;
	font-size: 12px;
	line-height: 15px;
	border: 1px solid $red-coral;
	border-radius: 5px;
	height: 18px;
	min-width: 18px;
	padding: 0 4px;
	top: -9px;
	right: 0px;
}
.btn-square {
	display: inline-block;
	width: 40px;
	height: 40px;
	background-color: #fff;
	border: 1px solid #D9D9D9;
	font-size: 16px;
	font-weight: bold;
	margin-right: 15px;
	margin-bottom: 15px;
	border-radius: 5px;
	color: $d-grey;
	&:hover {
		border: 1px solid $red-coral;
		color: $red-coral;
	}
}
.btn-square.active {
	background-color: $red-coral;
	border: 1px solid $red-coral;
	color: #FFFFFF;
}

.page-how-it-works {
	.container {
		max-width: 1050px;
	}
	.subtitle {
		font-family: $font-title;
		font-weight: bold;
		font-size: 36px;
		text-transform: uppercase;
		color: $d-grey;
		@media(max-width: 768px){
			font-size: 28px;
		}
	}
	p {
		font-weight: normal;
		font-size: 18px;
		line-height: 180%;
		color: $m-grey;
	}
	.row {
		margin-bottom: 100px;
		@media(max-width: 768px){
			margin-bottom: 50px;
		}
	}
	.row > .text {
		position: relative;
		padding-top: 90px;
		@media(max-width: 768px){
			order: 2;
		}
	}
	.bg-num {
		position: absolute;
		top: -80px;
		left: 0;
		font-size: 300px;
		font-weight: bold;
		color: #F0F0F0;
		z-index: -1;
	}
}

.page-qa {
	.container {
		max-width: 1050px;
	}
	.qa-item {
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
		border-radius: 5px;
		.qa-item-toggle {
			text-decoration: none;
			span {
				display: block;
				position: relative;
				padding: 15px 40px;
				font-size: 16px;
				font-weight: bold;
				color: $d-grey;
				transition: all .3s;
				&::before {
					content: '';
					position: absolute;
					left: 0;
					top: calc(50% - 15px);
					display: block;
					width: 2px;
					height: 30px;
					background-color: $red-coral;
				}
			}
		}
		.qa-item-toggle[aria-expanded="false"] span {
			background-color: #fff;
		}
		.qa-item-toggle[aria-expanded="true"] span {
			background-color: #ECEBF7;
			&::before {
				background-color: #000;
			}
		}
		.qa-item-text {
			padding: 30px 70px;
		}
		p {
			font-weight: normal;
			font-size: 14px;
			line-height: 180%;
			color: $d-grey;
		}
	}
	.qa-image {
		width: auto;
		height: auto;
		flex: 0 0 auto;
	}
}

.page-about {
	.pad {
		padding: 88px 20px;
		height: auto;

		.pad-title {
			font-size: 36px;
			line-height: 1.2em;
			margin: 0;
			padding: 0;
			@media(max-width: 768px){
				font-size: 26px;
			}
		}
	}
	.decor-container {
		position: relative;
		display: block;
		.about-decor-1 {
			display: block;
			padding: 0;
			position: absolute;
			width: 178px;
			height: 196px;
			top: calc(50% - 98px);
			left: -110px;
			z-index: -1;
		}
		.about-decor-2 {
			display: block;
			padding: 0;
			position: absolute;
			width: 110px;
			height: 83px;
			bottom: 40px;
			right: 30px;
			z-index: 2;
			@media(max-width: 768px){
				width: 55px;
				height: 41px;
				bottom: 20px;
			}
		}
	}
}
.our-values {
	.pad {
		padding: 50px 20px 20px 20px;
		img {
			padding: 0;
			margin-bottom: 35px;
		}
	}
}
.our-team {
	background: linear-gradient(to bottom, $l-grey,  $l-grey 100%);
	.pad {
		padding: 40px;
		@media(max-width: 768px){
			padding: 40px 25px;
		}
		.pad-img {
			min-width: 200px;
			@media(max-width: 768px){
				padding-bottom: 20px;
			}
		}
		.pad-text {
			padding-left: 40px;
			@media(max-width: 768px){
				padding-left: 0;
			}
			.pad-title {
				margin-bottom: 20px;
			}
			.position-title {
				font-size: 12px;
				font-weight: bold;
				text-transform: uppercase;
				color: #000000;
				margin-bottom: 10px;
			}
		}
	}
}

.social-link {
	display: inline-block;
	width: 50px;
	height: 50px;
	border: 1px solid $red-coral;
	background-color: #fff;
	border-radius: 6px;
	color: $red-coral;
	margin: 0.9rem 0.5rem 0.7rem;
	transition: background-color .3s, color .3s;
	&:hover {
		background-color: $red-coral;
		color: #FFFFFF;
	}
	& i {
		font-size: 24px;
		line-height: 49px;
		color: inherit;
	}
}

.form-pad {
	display: block;
	background-color: $l-grey;
	border-radius: 10px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding: 50px 75px;
	@media(max-width: 768px){
		padding: 30px 40px;
	}
	@media(max-width: 320px){
		padding: 15px;
	}
	.form-group {
		margin-bottom: 20px;
	}
	.password-group {
		position: relative;
		& .show-password {
			display: block;
			position: absolute;
			top: 0px;
			right: 0px;
			background: transparent;
			border: none;
			padding: 0;
			height: 48px;
			width: 48px;
			i {
				font-size: 16px;
				line-height: 48px;
				color: #A6A6A6;
				&:hover {
					color: #000000;
				}
			}
		}
	}
	.form-control {
		border: none;
	}
	.form-pad-subtitle {
		font-weight: bold;
		font-size: 16px;
		color: #101820;
	}
	& label {
		font-weight: bold;
		font-size: 16px;
		color: #101820;
		span {
			font-weight: normal;
			color: $m-grey;
		}
	}
	.checkbox-container label {
		font-weight: normal;
		color: $d-grey;
	}
	& .social-link {
		border: none;
	}
}

.page-contact {
	& .form-pad {
		max-width: 550px;
	}
	.form-title {
		font-family: $font-title;
		font-weight: normal;
		font-size: 20px;
		line-height: 28px;
		text-align: center;
		color: #000000;
		margin-bottom: 30px;
	}
	.btn-pill {
		text-transform: uppercase;
		display: block;
		margin: 20px auto;
		width: 140px;
	}
}
.contact-items {
	.pad {
		padding: 0 20px 76px 20px;
		p {
			font-weight: normal;
			font-size: 16px;
			line-height: 160%;
			text-align: center;
			color: #000000;
			padding: 0;
		}
		a:not(.social-link) {
			color: #000000;
		}
	}
}



.profile-editing, .profile-settings, .your-templates {
	.container {
		max-width: 1050px;
	}
	p {
		font-size: 16px;
		line-height: 160%;
		color: #A5A5A5;
	}
}

.profile-date {
	display: flex;
	justify-content: center;
}
.profile-date > div {
	padding: 0 20px;
	color: #a5a5a5;
	strong {
		color: $d-grey;
	}
}
.profile-date > div:first-child {
	border-right: 2px solid $l-grey;
}

.page-login, .page-registration {
	overflow: hidden;
	.btn-pill {
		min-width: 150px;
	}
	.gray-link {
		font-weight: bold;
		color: $m-grey;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
		
	}
	.red-link {
		font-weight: bold;
		color: $red-coral;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}

	}
	.register {
		margin: 0;
		padding-bottom: 1rem;
	}
	.register a {
		color: $d-grey;
		text-decoration: underline;
	}
	.login-img {
		display: block;
		position: absolute;
		@media(max-width: 768px){
			/* display: none; */
		}
	}
}
.page-login {
	.form-pad {
		position: relative;
		max-width: 550px;
		margin-bottom: 70px;
	}
	.login-img-lock {
		top: -10px;
		right: -40px;
	}
	.login-img-char {
		bottom: -24px;
		left: -160px;
	}
	.login-img-left {
		bottom: 20px;
		left: -210px;
		z-index: -1;
	}
	.login-img-right {
		bottom: 30px;
		right: -100px;
		z-index: -1;
	}
}
.page-registration {
	.form-pad {
		position: relative;
		max-width: 490px;
		margin-bottom: 70px;
		padding-left: 45px;
		padding-right: 45px;
	}
	.reg-img-left {
		bottom: 40px;
		left: -100px;
		z-index: -1;
	}
	.reg-img-right {
		bottom: -30px;
		right: -250px;
		z-index: -1;
	}
	.reg-img {
		display: block;
		position: absolute;
		@media(max-width: 768px){
			/* display: none; */
		}
	}
}

.profile-editing, .page-login, .page-registration, .page-reset-password {
	.section-title {
		text-transform: none;
	}
}

.page-reset-password {
	overflow: hidden;
	.section-description {
		max-width: 490px;
		width: 90%;
		margin-left: auto;
		margin-right: auto;
	}
	.form-pad {
		position: relative;
		max-width: 490px;
		margin-bottom: 70px;
		padding: 40px 45px;
	}
	.reset-img {
		display: block;
		position: absolute;
	}
	.reset-img-secur {
		bottom: -30px;
		left: -390px;
		z-index: -1;
	}
	.reset-img-char {
		bottom: -30px;
		right: -230px;
		z-index: -1;
	}
	.btn-pill {
		@media(max-width: 400px){
			font-size: 14px;
		}
		@media(max-width: 320px){
			font-size: 12px;
		}
	}
}
.page-terms-conditions-2 {
	.container {
		max-width: 1300px;
	}
	.pad {
		@media(max-width: 768px){
			padding: 40px 20px;
		}
	}
	.section-description {
		font-family: $font-title;
		font-size: 18px;
		line-height: 160%;
		font-weight: bold;
		color: $d-grey;
		max-width: 750px;
		margin-left: auto;
		margin-right: auto;
		@media (max-width: 360px){
			font-size: 16px;
		}
	}
	p {
		font-size: 14px;
		line-height: 180%;
		font-weight: normal;
		color: $d-grey;
		margin-top: 10px;
		span {
			color: $red-coral;
			font-weight: bold;
		}
	}
	.si {
		padding-left: 30px;
	}
	.sih {
		padding-left: 30px;
		font-weight: bold;
		margin-top: 15px;
	}
	.ssi {
		padding-left: 60px;
	}
	.sssi {
		padding-left: 90px;
	}
	.st {
		font-weight: bold;
		font-size: 18px;
		line-height: 180%;
		text-transform: uppercase;
		color: $red-coral;
		margin-top: 30px;
	}
	ol { counter-reset: item }
	li{ display: block }
	li:before { content: counters(item, ".") " "; counter-increment: item }
}

.page-my-airgreements {
	.section-title {
		text-transform: none;
	}


}
.offers-new {
	.container {
		max-width: 1300px;
	}
}

.pad.new {
	padding: 25px 60px 0px 60px;
	text-align: center;
	@media(max-width: 768px){
		padding: 15px 40px 15px 40px;
	}
	.pad-title {
		font-size: 30px;
		padding: 0;
		margin-bottom: 5px;
		@media(max-width: 768px){
			font-size: 20px;
		}
	}
	.btn-pill {
		margin-top: 15px;
	}
	&::before {
		content: '';
		display: block;
		position: absolute;
		width: 100px;
		height: 4px;
		border-radius: 0px 0px 4px 4px;
		background-color: $red-coral;
		top: 0px;
		left: calc(50% - 50px);
	}
}


.marker {
	font-size: 12px;
	padding: 4px 10px;
	border-radius: 5px;
	border: none;
	text-decoration: none;
	cursor: pointer;
	&.accepted {
		background: $green;
		color: #FFFFFF;
	}
}


.pad.air {
	padding: 30px 20px 10px 20px;
	margin: 0;
	text-align: center;
	.pad-title {
		font-size: 30px;
		@media (max-width: 768px) {
			font-size: 24px;
		}
	}
}


.air-set {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;

	.air-item {
		border: 2px solid $l-grey;
		border-radius: 0px 0px 5px 5px;
		flex-basis: 48%;
		margin: 10px 10px;
		padding: 15px;
		display: flex;
		justify-content: flex-start;
		max-width: 420px;
		position: relative;
		@media(max-width: 370px){
			min-width: 280px;
		}
		&.accepted::before {
			content: '';
			display: block;
			position: absolute;
			top: -2px;
			left: 0;
			height: 4px;
			width: 100%;
			background-color: $green;
		}
		&.pending::before {
			content: '';
			display: block;
			position: absolute;
			top: -2px;
			left: 0;
			height: 4px;
			width: 100%;
			background-color: $yellow;
		}
		&.accepted .marker {
			background-color: $green;
			color: #FFFFFF;
		}
		&.pending .marker {
			background-color: $yellow;
		}
		.air-item-img {
			max-width: 75px;
			min-width: 75px;
		}
		.air-item-text {
			margin-left: 15px;
			padding-right: 30px;
			text-align: left;
		}
		.air-item-text-centred {
			margin: 0;
			width: 100%;
			padding: 0px;
			text-align: center;
		}
		.name {
			font-weight: 500;
			font-size: 16px;
			line-height: 16px;
			color: $d-grey;
			margin-bottom: 7px;
		}
		.date {
			font-weight: normal;
			font-size: 12px;
			line-height: 12px;
			color: $d-grey;
			margin-top: 10px;
			margin-bottom: 0;
		}
		.btn-sticky-container {
			display: flex;
			flex-direction: column;
			max-width: 26px;
			position: absolute;
			right: -1px;
			top: 20px;
			.btn-sticky {
				background: #FFFFFF;
				border: 2px solid $l-grey;
				border-radius: 5px 0px 0px 5px;
				padding: 0;
				width: 26px;
				height: 26px;
				text-align: center;
				margin-bottom: 5px;
				color: $d-grey;
				i {
					font-size: 12px;
					display: block;
				}
			}
			.btn-sticky:hover {
				background-color: $red-coral;
				i {
					color: #FFF;
				}
			}
		}
	}
}

.offers-trash {
	.container {
		max-width: 1300px;
	}
}
.pad.deleted {
	text-align: center;
	padding: 30px 50px 30px 50px;
	margin: 0;
	text-align: center;
	.pad-title {
		font-size: 30px;
		@media (max-width: 768px) {
			font-size: 24px;
		}
	}
	.data-deleted {
		font-weight: 500;
		font-size: 16px;
		color: $d-grey;
		margin-top: 15px;
	}
	&::before {
		content: '';
		display: block;
		position: absolute;
		width: 100px;
		height: 4px;
		border-radius: 0px 0px 4px 4px;
		background-color: $d-grey;
		top: 0px;
		left: calc(50% - 50px);
	}
}

/* Modals */
.modal-backdrop {
	background-color: rgb(16, 24, 32);
	opacity: 0.9!important;
}
.modal-title {
	font-family: $font-title;
	font-weight: bold;
	font-size: 48px;
	line-height: 120%;
	text-align: center;
	color: $red-coral;
	@media(max-width: 990px){
		font-size: 36px;
	}
	@media(max-width: 576px){
		font-size: 28px;
	}
	@media(max-width: 320px){
		font-size: 20px;
	}
}
.modal-subtitle {
	font-family: $font-title;
	font-weight: bold;
	font-size: 24px;
	text-align: center;
	color: #000000;
	margin-top: 15px;
	@media(max-width: 990px){
		font-size: 20px;
	}
	@media(max-width: 576px){
		font-size: 16px;
	}
	@media(max-width: 320px){
		font-size: 14px;
	}
}
.modal-header {
	justify-content: center;
	padding: 50px 10px 15px 10px;
	border-bottom: none;
}
.modal-content {
	border-radius: 10px;
	box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
	border: none;
}
.modal-footer {
	justify-content: center;
	padding: 10px 10px 50px 10px;
	border-top: none;
}
.modal-message {
	font-weight: normal;
	font-size: 18px;
	line-height: 180%;
	text-align: center;
	color: $m-grey;
	max-width: 490px;
	margin: 0 auto;
}
.container-search-form {
	background-color: $l-grey;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 15px;
	max-width: 100%;
	.search-form {
		display: inline-block;
		vertical-align: middle;
		width: 490px;
		margin-right: 20px;
		@media(max-width: 768px){
			width: calc(100vw - 100px);
		}

	}
}
.party-members {
	display: block;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	.party-member {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		height: 36px;
		padding: 0px 50px 0px 20px;
		font-size: 14px;
		line-height: 36px;
		font-weight: 500;
		background-color: #fff;
		border: 1px solid #EB4250;
		border-radius: 10px;
		margin-right: 10px;
		margin-bottom: 10px;
		.remove-member {
			display: block;
			position: absolute;
			top: -1px;
			right: -1px;
			width: 36px;
			height: 36px;
			background-color: $red-coral;
			border: none;
			border-radius: 0px 10px 10px 0px;
			background-image: url('../images/core/icon-cross-white.svg');
			background-repeat: no-repeat;
			background-position: 50% 50%;
		}
	}
}
.modal-body .party-members {
	max-width: 500px;
	text-align: left;
}

.applicants-list {
	display: block;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	.applicant-item {
		display: inline-block;
		text-align: left;
		background: #FFFFFF;
		border: 1px solid #F1F3F5;
		border-radius: 10px;
		padding: 20px;
		margin: 10px 10px;
		width: 480px;
		max-width: calc(100% - 20px);
		box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
		transition: border .3s;
		&:hover {
			border: 1px solid #EB4250;
		}
	}
	.applicant-image {
		display: inline-block;
		vertical-align: middle;
		width: 52px;
		height: 52px;
		& figure {
			overflow: hidden;
			border-radius: 8px;
			max-height: 100%;
			margin: 0;
		}
		& figure img {
			object-fit: contain;
		}
	}
	.applicant-name {
		display: inline-block;
		vertical-align: middle;
		font-weight: 500;
		font-size: 18px;
		color: $d-grey;
		padding: 15px;
		width: calc(100% - 110px);
		@media(max-width: 576px){
			font-size: 16px;
		}
	}
	.applicant-add, .applicant-remove {
		display: inline-block;
		vertical-align: middle;
		width: 40px;
		height: 40px;
		border: 1px solid $red-coral;
		border-radius: 10px;
		padding: 0;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		transition: background-color .3s;
	}
	.applicant-add {
		background-color: #fff;
		background-image: url('../images/core/icon-plus-red.svg');
		&:hover {
			background-color: $red-coral;
			background-image: url('../images/core/icon-plus-white.svg');
		}
	}
	.applicant-remove {
		background-color: $red-coral;
		background-image: url('../images/core/icon-cross-white.svg');
		&:hover {
			background-color: $dark-coral;
			background-image: url('../images/core/icon-cross-white.svg');
		}
	}
}
.modal-body .applicants-list {
	max-width: 500px;
	max-height: 270px;
	padding-right: 20px;
	overflow-y: scroll;
	.applicant-item {
		display: block;
		width: 100%;
		padding: 4px;
		box-shadow: none;
		margin: 0 0 10px 0;
	}
}

.your-templates {
	.pad {
		padding: 20px 30px;
		margin-bottom: 0;
	}
	.pad-headline {
		margin-bottom: 20px;
	}
	.pad-title {
		font-family: inherit;
		font-size: 24px;
		display: inline-block;
		vertical-align: middle;
		padding-right: 32px;
		border-right: 2px solid #ECEEF0;
		@media(max-width: 576px){
			display: block;
			border-right: none;
			padding: 10px 0;
		}
	}
	.date {
		display: inline-block;
		vertical-align: middle;
		font-weight: 500;
		font-size: 14px;
		color: $m-grey;
		padding-left: 30px;
		@media(max-width: 576px){
			display: block;
			padding: 10px 0;
		}
	}
}
.new-template {
	.form-pad-inner {
		max-width: 900px;
		margin-left: auto;
		margin-right: auto;
	}
}

.profile-public {
	.section-grey-out {
		margin-top: 60px;
	}
	.pad.profile {
		max-width: 1010px;
		margin-left: auto;
		margin-right: auto;
		padding: 40px;
		display: flex;
		align-items: flex-start;
		@media (max-width: 768px){
			flex-direction: column;
			align-items: center;
			padding: 20px;
		}
		.pad-title {
			margin-bottom: 20px;
			font-size: 30px;
			@media (max-width: 768px){
				text-align: center;
				margin-top: 20px;
				font-size: 22px;
			}
		}
		.profile-image {
			position: relative;
			flex: 0 0 170px;
			height: 170px;
			width: 170px;
			border-radius: 10px;
			overflow: hidden;
			&::after {
				content: '';
				display: block;
				position: absolute;
				top: 5px;
				left: 5px;
				width: 160px;
				height: 160px;
				border-radius: 8px;
				border: 2px solid #FFFFFF;
			}
			figure {
				margin: 0;
			}
			figure img {
				object-fit: contain;
			}
		}
		.profile-text {
			padding-left: 30px;
			@media (max-width: 768px){
				padding-left: 0px;
			}
		}
		.btn-group {
			margin-top: 20px;
		}
	}
	.section-title.small {
		margin-top: 50px;
		margin-bottom: 20px;
	}
}

.properties-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 10px;
	&.centred {
		justify-content: center;
	}
	.marker {
		font-size: 16px;
		line-height: 40px;
		font-weight: 500;
		height: 40px;
		border: 1px solid #f0f0f0;
		background-color: #fff;
		border-radius: 5px;
		padding: 0px 25px;
		margin-right: 15px;
		margin-bottom: 10px;
		color: $d-grey;
		i {
			margin-right: 5px;
		}
	}
}

.btn-group .btn-pill {
	margin-right: 20px;
}

.mrkup-container {
	border-radius: 10px 10px 0px 0px;
	background: $l-grey;
	.mrkup-header {
		padding: 30px 0 5px 0;
		.mrkup-title {
			display: block;
			padding: 0px 40px;
			font-size: 24px;
			color: $d-grey;
			font-weight: bold;
			position: relative;
			margin-bottom: 10px;
			&::before {
				content: '';
				display: block;
				position: absolute;
				height: 28px;
				width: 4px;
				border-radius: 0px 4px 4px 0px;
				background-color: $red-coral;
				left: 0;
				top: calc(50% - 14px);
			}
		}
		p {
			color: $m-grey;
			font-size: 16px;
			line-height: 180%;
			padding: 0 40px;
		}
	}
	.mrkup-list {
		background-color: #fff;
		.markup-item {
			display: inline-block;
			padding: 10px 20px;
			.markup-item-name {
				display: inline-block;
				vertical-align: middle;
				font-size: 14px;
				color: $blue;
			}
			.markup-item-action {
				display: inline-block;
				vertical-align: middle;
				width: 36px;
				height: 36px;
				background-color: #fff;
				border: 1px solid $l-grey;
				border-radius: 6px;
				margin: 0 5px;
				i {
					line-height: 36px;
					font-size: 14px;
					color: inherit;
				}
				&:hover {
					color: $red-coral;
					border: 1px solid $red-coral;
				}
			}
		}

	}
}
.editor-pad {
	background-color: $l-grey;
	border-radius: 10px 10px 0px 0px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.stub-text {
	display: block;
	font-size: 12vmin;
	text-transform: uppercase;
	color: #DADCDE;
	text-align: center;
	padding: 2vmin;
}
.price-big {
	color: $d-grey;
	display: block;
	margin: 0 auto 25px auto;
	max-width: 200px;
	text-align: center;
	font-family: $font-title;
	font-weight: bold;
	font-size: 36px;
}
.description-pad {
	background: $l-grey;
	font-size: 18px;
	line-height: 160%;
	text-align: center;
	color: #000000;
	padding: 30px;
	border-radius: 6px;
	margin: 40px 0 0 0;
	p {
		display: block;
		max-width: 870px;
		margin-left: auto;
		margin-right: auto;
	}
}
.airgreement {
	padding: 50px 130px 70px 130px;
	width: 100%;
	box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	font-size: 16px;
	color: $d-grey;
	@media(max-width: 768px){
		padding: 30px 40px 50px 40px;
	}
	.airgreement-title {
		font-weight: bold;
		font-size: 36px;
		line-height: 120%;
		text-align: center;
		color: $d-grey;
		@media(max-width: 768px){
			font-size: 24px;
		}
	}
	.airgreement-subtitle {
		font-size: 24px;
		font-weight: bold;
		text-align: center;
		margin-top: 30px;
		margin-bottom: 15px;
	}
	p {
		font-weight: normal;
		font-size: 16px;
		line-height: 160%;
		color: $d-grey;
		margin-bottom: 15px;
	}
	.form-control {
		height: 40px;
		margin: 7px 0px;
	}
	.form-control.inline {
		display: inline-block;
		width: auto;
		vertical-align: middle;
		margin: 7px 12px;
	}

	.form-control::placeholder {
		font-size: 14px;
		color: #A6A6A6;
		line-height: 28px;
	}
}

.page-thank-you {
	.thank-you-container {
		margin-left: auto;
		margin-right: auto;
		display: flex;
		align-items: flex-end;
		justify-content: center;
		margin-bottom: 50px;
		@media(max-width: 1200px){
			flex-direction: column;
			align-items: center;
		}
	}
	.thank-you-image {
		margin-bottom: 20px;
	}
	.thank-you-text {
		@media(min-width: 1200px){
			padding-left: 30px;
		}
		.section-title {
			color: $red-coral;
			margin-bottom: 5px;
			&::before {
				display: none;
			}
		}
		p {
			font-family: $font-title;
			font-weight: bold;
			text-align: center;
			font-size: 24px;
			line-height: 120%;
			color: #000000;
		}
	}
}

#start-contract .modal-footer, #accept-airgreement .modal-footer {
	padding-top: 0px;
}

.page-airgreement-history {
	.section-title {
		margin: 30px auto 10px auto;
	}
	.section-title::before {
		display: none;
	}
}
.pointer-blue {
	display: inline-block;
	background: $blue;
	color: #FFFFFF;
	border-radius: 5px;
	padding: 7px 30px;
}
.exp {
	font-size: 14px;
	font-style: italic;
	text-align: center;
	display: inline-block;
}
.form-control ~ .exp {
	display: block;
}
.bs-datepicker {
	padding-right: 30px;
	position: relative;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='currentColor' d='M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z'%3E%3C/path%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: right 10px top 45%;
	background-size: 14px 18px;
}
.form-time24, .form-time12 {
	width: 90px!important;
	text-align: center;
}
span.box {
	display: inline-block;
	width: 26px;
	height: 26px;
	vertical-align: middle;
	border: 1px solid $d-grey;
	box-sizing: border-box;
	border-radius: 6px;
	margin-left: 10px;
}
span.box.checked {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'%3E%3C/path%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: 14px 10px;
}
span.box.checked.ok {
	border: 1px solid #219653;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23219653' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'%3E%3C/path%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: 16px 16px;
}
span.box.ok {
	border: 1px solid #219653;
}
span.box.canceled {
	position: relative;
	border: 1px solid $red-coral;
	&:after {
		content: '';
		display: block;
		position: absolute;
		width: 100%;
		height: 2px;
		top: calc(50% - 1px);
		background-color: $red-coral;
	}
}
span.box.checked.canceled {
	position: relative;
	border: 1px solid $red-coral;
	background-color: $red-coral;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23FFFFFF' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'%3E%3C/path%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: 16px 16px;
	&:after {
		content: '';
		display: block;
		position: absolute;
		width: 100%;
		height: 2px;
		top: calc(50% - 1px);
		background-color: #fff;
	}
}
span.box.added {
	border: 1px solid #407BFF;
}
span.box.checked.added {
	border: 1px solid #407BFF;
	background-color: #407BFF;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23FFFFFF' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'%3E%3C/path%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: 16px 16px;
}
.text-ok {
	color: #219653;
	font-weight: bold;
}
.text-canceled {
	color: $red-coral;
	text-decoration: line-through;
	font-weight: bold;
}
.text-added {
	color: #407BFF;
	font-weight: bold;
}

.shop-templates {
	overflow: hidden;
}
.air-set-slider,
.shop-templates-slider {
	@media(max-width: 576px){
		max-width: calc(100% - 5px);
		margin-left: auto;
		margin-right: auto;
		display: block;
		position: relative;
	}
}
.shop-templates .slick-prev, .shop-templates .slick-next {
	@media(min-width: 576px){
		display: none;
	}
	@media(max-width: 576px){
		display: block;
		top: calc(50% - 20px);
	}
}

.page-contributor-profile .section-grey-solid {
	overflow: hidden;
	max-width: 100%;
	position: relative;
}
.contributor-profile-slider {
	@media(max-width: 576px){
		width: calc(100% - 20px);
		margin-left: auto;
		margin-right: auto;
		display: block;
		position: relative;
	}
	.slick-prev {
		left: -10px;
	}
	.slick-next {
		right: -10px;
	}
	.col-12 {
		@media(max-width: 576px){
			padding: 25px;
		}
	}
	.btn-group {
		@media(max-width: 370px){
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
		}
		.btn-pill {
			@media(max-width: 370px){
				margin: 5px 0;
			}
		}
	}
}

.airgreements-slider {
	@media(max-width: 576px){
		margin-left: 10px;
		margin-right: 10px;
	}
}

.cookie-consent {
	margin: 1%;
	font-weight: lighter;
	font-size: 117%;
	button {
		text-transform: uppercase;
		display: block;
		margin: 20px auto;
		width: 140px;
		background-color: #EB4250;
		color: #FFF;
		border: 1px solid #EB4250;
		cursor: pointer;
		border-radius: 20px;
		font-size: 14px;
		line-height: 36px;
		font-weight: 500;
		text-decoration: none;
		transition: background-color 0.3s, color 0.3s, border 0.3s;
	}
}

@media (max-width: 576px) {
	.footer {
		.footer-first {
			flex-direction: row;
			text-align: center;
			> div {
				width: 100%;
			}
		}
		.flag-templates-slider {
			margin-top: 20px;
			margin-bottom: 20px;
			column-count: auto;
			column-gap: normal;

		}
	}
	.flag-templates-slider {
		.slick-prev {
			left: 0;
		}
		.slick-next {
			right: 0;
		}
		.slick-list.draggable{
			width: 100% !important;
		}
	}
}
.template-price {
	font-family: "Comfortaa", Helvetica;
	color: #101820;
	font-weight: 700;
	font-size: 26px;
}
.page-login,
.residential-lease-airgreement {
	input[type="checkbox"] {
		position: relative;
		-webkit-appearance: none;
		-moz-appearance: none;
		width: 26px;
		height: 26px;
		background-color: #FFFFFF;
		border-radius: 6px;
		border: 1px solid #cac8c8;
		top: 0px;
		margin-bottom: 2px;
	}
	input[type="radio"] {
		position: relative;
		-webkit-appearance: none;
		-moz-appearance: none;
		width: 20px;
		height: 20px;
		background-color: #FFFFFF;
		border: 1px solid #cac8c8;
		top: 4px;
		border-radius: 50px;
	}
	input[type="radio"]:focus ,
	input[type="checkbox"]:focus {
		outline: none;
	}
	input[type="radio"]:checked {
		background-color: $red-coral;
	}
	input[type="checkbox"]:checked {
		background-color: $red-coral;
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
		background-repeat: no-repeat;
		background-size: 13px;
		background-position: center;
	}
}
body {
	& > div.container {
		left: 0;
		right: 0;
	}
}
.page-login {
	width: 100%;
	text-align: center;
	min-height: calc(100vh - 133px);
	.btn-pill.big {
		line-height: 36px;
		margin-left: 0!important;
	}
	& > div {
		min-height: calc(100vh - 133px);
		display: flex;
		flex-direction: column;
		& > .bg-white {
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: 100%;
			flex-grow: 1;
		}
	}
	form {
		position: relative;
		max-width: 550px;
		margin-bottom: 0;
		margin-left: auto;
		margin-right: auto;
		margin-top: 10px;
		width: 100%;
		& + div {
			margin-right: 15px;
			margin-left: 15px;
		}
	}
	.logo-login svg {
		width: 100%;
	}
	label {
		width: 75%;
		//text-align: right;
		@media(max-width: 767px) {
			//width: auto;
			text-align: center;
		}
		&[for="remember_me"] {
			width: 100%;
			margin-bottom: 2rem;
			> input[type=checkbox] {
				margin-left: 0;
			}
		}
	}
	input {
		border: 1px solid #ced4da;
		outline: none;
		border-radius: .25rem;
		height: 38px;
		margin-left: 10px;
		&#password_confirmation {
			margin-bottom: 0.7rem;
		}
	}
}
.footer-second {
	a {
		color: #101820;
		margin: 5px 0;
		display: inline-block;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
}
.popup {
	position: fixed;
	top: 0px;
	background: rgb(0 0 0 / 69%);
	width: 100%;
	height: 100%;
	z-index: 999;
	padding: 5%;
	right:0px;
	img { 
		margin:4% 0 0 0 ;
	}
}
.avatar {border-radius: 2%; }
.buynowbutton{margin: 2%; }

.citybottom{
	background-image: url(http://localhost:8000/images/city-bg.svg);
	background-position: bottom;
	background-repeat: repeat-x;
}

@media print {
	.buttons-list-center, .btn-sticky-container, .btn-pill, .btn-sticky, footer ul  {
		display:none;
	}
	.air-item-text {
		display: inline-block;
	}
	.air-item {
		margin:0px !important; 
		padding:5px 8px !important;
	}
	.avatar{
		height:3em ;
	}
	footer ul, #to-top, #nav-toggle{
		display: none !important;
	}
	.section-grey-solid-out{
		background-color: white !important;
	}
}
